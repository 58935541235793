@font-face {
  font-family: "Aestethe";
  src: url("./assets/Aesthete.ttf") format("truetype");
}
@font-face {
  font-family: "poppins";
  src: url("./assets/Poppins-Light.ttf") format("truetype");
}
@font-face {
  font-family: "poppins-bold";
  src: url("./assets/Poppins-ExtraBold.ttf") format("truetype");
}

* {
  box-sizing: content-box;
}

/* html {
  background: linear-gradient(to left, #aecd97, #AECD97);
  background-size: 400% 400%;
  animation: backgroundMoving 25s infinite ease;
  @keyframes backgroundMoving {
    0% {
      background-position: 92% 0%;
    }
    50% {
      background-position: 9% 100%;
    }
    100% {
      background-position: 92% 0%;
    }
  }
} */

html {
  background: #656439;
}

html:before {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: -30%;
  width: 100%;
  height: 100%;
  z-index: -10;
  /* background: url(/src/assets/images/background.png) no-repeat center center; */
  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "ChalkAbout";
  src: local("ChalkAbout"), url("./assets/Chalkabout.otf") format("opentype");
}

@font-face {
  font-family: "ChalkAboutItalic";
  src: local("ChalkAboutItalic"),
    url("./assets/Chalkabout\ Italic.otf") format("opentype");
}

@font-face {
  font-family: "crayonHand";
  src: local("crayonHand"), url("./assets/crayonhand.ttf");
}
